import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import LandingPage from "./LandingPage";
import ChatPage from "./ChatPage";
import DaredPage from "./DaredPage";
import firebase from "./firebase";
import MissingDarePage from "./MissingDarePage";
import RoomListPage from "./RoomListPage";
import TermsAndConditionsPage from "./TermsAndConditionsPage";
import OnlyChat from "./OnlyChat";

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const App = () => {
  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }
  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  return (
    <div className="appContainer">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/chat/:roomId"
          element={<ChatPage firebase={firebase} />}
        />
        <Route
          path="/onlyChats/:roomId"
          element={<OnlyChat firebase={firebase} />}
        />
        <Route path="/dared" element={<DaredPage />} />
        <Route path="/missing-dare" element={<MissingDarePage />} />
        <Route path="/room-list" element={<RoomListPage />} />
        <Route path="/tos" element={<TermsAndConditionsPage />} />
        <Route path="*" element={<LandingPage />} />
      </Routes>
    </div>
  );
};

export default App;
