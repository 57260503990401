import React, { useEffect, useState } from 'react';

const TikTokEmbed = ({ url, roomId }) => {
    const [tikTokData, setTikTokData] = useState(null);
    useEffect(() => {
        // Load the Instagram Embed script dynamically
        const script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.src = 'https://www.tiktok.com/embed.js';
        document.body.appendChild(script);
        const fetchData = async () => {
            try {
                // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint
                const response = await fetch('https://www.tiktok.com/oembed?url=' + url);
                const data = await response.json();

                // Assuming the API response has an 'html' property
                setTikTokData(data.html);
            } catch (error) {
                console.error('Error fetching TikTok data:', error);
            }
        };

        fetchData();


        return () => {
            // Remove the script when the component unmounts
            document.body.removeChild(script);
          };
    }, []);

  return <div>
  {tikTokData && (
    <div dangerouslySetInnerHTML={{ __html: tikTokData }} />
  )}
</div>
}

export default TikTokEmbed;
