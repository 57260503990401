// ModalContext.js

import React, { createContext, useContext, useState, useEffect } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onModalClose, setOnModalClose] = useState(null);

  const openModal = (content, onClose) => {
    setModalContent(content);
    setOnModalClose(() => onClose);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    if (onModalClose) {
      onModalClose();
    }
    setModalContent(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Close modal on Escape key press

  useEffect(() => {
    if (!isModalOpen && onModalClose) {
      onModalClose();
      setOnModalClose(null); // Reset the callback after executing
    }
  }, [isModalOpen, onModalClose]);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {isModalOpen && (
        <div id="modal-root">
          <div className="modal">
            <div className="modal-content">
              {modalContent}
              <button onClick={closeModal}>Close</button>
            </div>
          </div>
        </div>
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
