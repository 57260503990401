import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Joyride from "react-joyride";
import firebase from "./firebase";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { useModal } from "./ModalContext";

const LandingPage = () => {
  const walkthrough = localStorage.getItem("walkthrough");
  const [runWalkthrough, setRunWalkthrough] = useState(
    walkthrough ? false : true
  );
  const { openModal } = useModal();
  const fileInputRef = useRef(null); // Define file input ref
  const [minutesInput, setMinutesInput] = useState("");
  const [dareLink, setDareLink] = useState(""); // New state for Dare to Link
  const [experienceTitle, setExperienceTitle] = useState(""); // New state for Dare to Link
  const [experienceDescription, setExperienceDescription] = useState(""); // New state for Dare to Link
  const navigate = useNavigate();
  const [reallyDare, setReallyDare] = useState(1);
  const [leftParam, setLeftParam] = useState(50);
  const [topParam, setTopParam] = useState(-80);
  const [contentType, setContentType] = useState(2); // Default to link mode
  const [selectedImage, setSelectedImage] = useState(null);
  const [censorshipEnabled, setCensorshipEnabled] = useState(false);

  const generateRoomUniqueId = () => {
    // Generate a unique ID (you can use a library like uuid or generate your own unique ID logic)
    const newUniqueId = Math.random().toString(36).substring(2, 27); // Replace this with your actual logic

    // Store the unique ID in local storage
    localStorage.setItem("roomOwnerId", newUniqueId);

    return newUniqueId;
  };

  const steps = [
    {
      target: ".wt-dare-status", // Use the same class used for tooltips
      content: "This is gonna show if you dare enough or just playin' around!",
      placement: "top",
    },
    {
      target: ".wt-dare-type", // Use the same class used for tooltips
      content:
        "For now, the team provided an amazing 2 options to dare! You can paste a Link or.. when you switch, you can upload an image if you brave enough!",
      placement: "top",
    },
    {
      target: ".wt-dare-link", // Use the same class used for tooltips
      content:
        "Here you can paste a Twitch Channel (which is live obviously), Youtube Video, your own Twitch Stream, an Instagram post or any other SECURE URL(https).",
      placement: "top",
    },
    {
      target: ".wt-dare-censorship", // Use the same class used for tooltips
      content:
        "This is where the things got interesting! By default, our team provide a censor free option (without URLs ofcourse, you dont want OLX links with 'Vand Golf 4'). But if you click, the whole world get tamed and you are in your safezone again.",
      placement: "top",
    },
    {
      target: ".wt-dare-minutes", // Use the same class used for tooltips
      content:
        "How long can you resist? Type in the number of minutes between 1 and 20. We know you won't resist more!",
      placement: "top",
    },
    {
      target: ".wt-dare-trigger", // Use the same class used for tooltips
      content:
        "Be careful when you click this! This is the trigger for your people. It can hurt you!",
      placement: "top",
    },
    {
      target: ".wt-dare-unkown", // Use the same class used for tooltips
      content:
        "Okay, we got here! If you didn't 'enjoy' enough, you can join random rooms for daring.",
      placement: "top",
    },
    // Add more steps as needed
  ];

  const handleJoyrideCallback = (data) => {
    if (data.status === "finished") {
      setRunWalkthrough(false);
      localStorage.setItem("walkthrough", true);
    }
  };

  const handleSwitchToggle = (type) => {
    setContentType(type);
    setSelectedImage(null); // Reset selected image when switching modes
  };

  const handleImageSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1 * 1024 * 1024) {
        openModal("Maximum 1Mb image size");
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  useEffect(() => {
    if (censorshipEnabled) {
      document.body.classList.add("censored");
    } else {
      document.body.classList.remove("censored");
    }
  }, [censorshipEnabled]);

  const handleGenerateCustomLink = async () => {
    const minutes = parseInt(minutesInput, 10);
    // Regular expression for validating Twitch or YouTube links
    const linkRegex = /(https:\/\/[^\s]+)/;

    if (
      isNaN(minutes) ||
      minutes <= 0 ||
      minutes > 20 ||
      (contentType === 2 && !linkRegex.test(dareLink)) ||
      (contentType === 1 && !selectedImage)
    ) {
      openModal(
        "You need to enter the number of minutes between 1 and 20 and you must paste a SECURE URL (https) or upload a photo for this to work!"
      );
      return;
    }

    if (reallyDare < 3) {
      if (reallyDare === 1)
        openModal("Are you really sure? Did you read the TOS?", () => {
          setLeftParam(getRandomArbitrary(0, 80));
          setTopParam(getRandomArbitrary(-100, 100));
          setReallyDare(reallyDare + 1);
        });
      if (reallyDare === 2)
        openModal("Bad things can happen!", () => {
          setLeftParam(getRandomArbitrary(0, 80));
          setTopParam(getRandomArbitrary(-100, 100));
          setReallyDare(reallyDare + 1);
        });
      return;
    }

    if (contentType === 3) {
      try {
        const newRoomId = generateUniqueId();
        const createdAt = firebase.firestore.FieldValue.serverTimestamp();

        // Store the experience details on Firestore
        await firebase.firestore().collection("rooms").doc(newRoomId).set({
          countdown: minutes,
          experienceTitle: experienceTitle,
          experienceDescription: experienceDescription,
          createdAt: createdAt,
          roomOwnerId: generateRoomUniqueId(),
          playtime: 0,
          censorshipEnabled: censorshipEnabled,
          roomType: "experience", // New roomType property
        });

        const creatorId = generateUniqueId();
        localStorage.setItem("creatorId", creatorId);

        // Redirect to the chat room with the new room ID
        navigate(`/chat/${newRoomId}`);
      } catch (error) {
        console.error("Error storing experience details:", error);
      }
    } else if (contentType === 2) {
      if (
        isNaN(minutes) ||
        minutes <= 0 ||
        minutes > 20 ||
        !linkRegex.test(dareLink) // Check if Dare to Link is a valid Twitch or YouTube link
      ) {
        openModal(
          "Please enter a valid number between 1 and 20 for minutes, and provide a SECURE URL (https)."
        );
      } else {
        try {
          const newRoomId = generateUniqueId();
          const createdAt = firebase.firestore.FieldValue.serverTimestamp();

          // Store the countdown duration and dare link on Firestore
          await firebase.firestore().collection("rooms").doc(newRoomId).set({
            countdown: minutes,
            dareLink: dareLink, // Store dareLink only if not screen sharing
            createdAt: createdAt,
            roomOwnerId: generateRoomUniqueId(),
            playtime: 0,
            censorshipEnabled: censorshipEnabled, // Censor flag
          });

          await firebase
            .firestore()
            .collection("rooms")
            .doc(newRoomId)
            .collection("videoStatus")
            .doc("status")
            .set({
              playing: false, // Initial playing status
              playtime: 0, // Initial playtime
            });

          const creatorId = generateUniqueId();
          localStorage.setItem("creatorId", creatorId);

          // Redirect to the chat room with the new room ID
          navigate(`/chat/${newRoomId}`);
        } catch (error) {
          console.error(
            "Error storing countdown duration and dare link:",
            error
          );
        }
      }
    } else {
      if (isNaN(minutes) || minutes <= 0 || minutes > 20 || !selectedImage) {
        openModal("Please select an image and type the number of minutes.");
        return;
      }

      if (selectedImage && selectedImage.size > 1 * 1024 * 1024) {
        openModal("Image size should be less than 1 MB.");
        return;
      }

      try {
        const newRoomId = generateUniqueId();
        const createdAt = firebase.firestore.FieldValue.serverTimestamp();

        // Store the image URL and other details on Firestore
        await firebase.firestore().collection("rooms").doc(newRoomId).set({
          countdown: minutes,
          image: selectedImage,
          createdAt: createdAt,
        });

        // Redirect to the chat room with the new room ID
        navigate(`/chat/${newRoomId}`);
      } catch (error) {
        console.error("Error storing image URL:", error);
      }
    }
  };

  const generateUniqueId = () => {
    return Math.random().toString(36).substring(2, 7);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleGenerateCustomLink();
    }
  };

  return (
    <div className="text-center card landing-card">
      <div className="landing-card-links">
        <Link to={"/tos"} className="wt-dare-tos">
          Terms and conditions
        </Link>
        <span>&bull;</span>
        <Link to={"/room-list"} className="wt-dare-unkown">
          Dare the unkown?
        </Link>
      </div>
      <picture className="wt-dare-status">
        <source
          srcSet={
            censorshipEnabled
              ? "https://fonts.gstatic.com/s/e/notoemoji/latest/1f424/512.webp"
              : "https://fonts.gstatic.com/s/e/notoemoji/latest/1f608/512.webp"
          }
          type="image/webp"
        />
        <img
          src={
            censorshipEnabled
              ? "https://fonts.gstatic.com/s/e/notoemoji/latest/1f424/512.gif"
              : "https://fonts.gstatic.com/s/e/notoemoji/latest/1f608/512.gif"
          }
          alt="😈"
          width="80"
          height="80"
        />
      </picture>
      <h1 className="mb-4">Double Dare.</h1>
      <div className="switch-container wt-dare-type">
        <div class="container">
          <div class="tabs">
            <input
              type="radio"
              id="radio-1"
              name="tabs"
              checked={contentType === 1 ? true : false}
              onChange={() => handleSwitchToggle(1)}
            />
            <label class="tab" for="radio-1">
              Image
            </label>
            <input
              type="radio"
              id="radio-2"
              name="tabs"
              checked={contentType === 2 ? true : false}
              onChange={() => handleSwitchToggle(2)}
            />
            <label class="tab" for="radio-2">
              Link
            </label>
            <input
              type="radio"
              id="radio-3"
              name="tabs"
              checked={contentType === 3 ? true : false}
              onChange={() => handleSwitchToggle(3)}
            />
            <label class="tab" for="radio-3">
              Experience
            </label>
            <span class="glider"></span>
          </div>
        </div>
      </div>
      {contentType === 3 && (
        <div className="mt-4">
          <label className="mr-2">
            Please be careful. Other people experiences may be tough to handle!
          </label>
          <Tippy content="Give a topic to speak their souls out!">
            <input
              type="text"
              value={experienceTitle}
              onChange={(e) => setExperienceTitle(e.target.value)}
              className="form-control wt-experience-title"
              placeholder="The title for the experiences"
            />
          </Tippy>
          <Tippy content="Describe a little more what people should be talking about!">
            <input
              type="text"
              value={experienceDescription}
              onChange={(e) => setExperienceDescription(e.target.value)}
              className="form-control wt-experience-description"
              placeholder="Describe a little more "
            />
          </Tippy>
        </div>
      )}
      {contentType === 2 && (
        <div className="mt-4">
          <label className="mr-2">Dare to Link</label>
          <Tippy content="Mmm... a Twitch, Youtube, an Instagram or any other SECURE URL(https). Choose wisely!">
            <input
              type="text"
              value={dareLink}
              onChange={(e) => setDareLink(e.target.value)}
              className="form-control wt-dare-link"
              placeholder="Your link here."
            />
          </Tippy>
        </div>
      )}
      {contentType === 1 && (
        <div className="mt-4">
          <div
            className="drop-zone"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={() => fileInputRef.current.click()}
          >
            {selectedImage ? (
              <>
                <FontAwesomeIcon icon={faRefresh} size="xl" />
                <div className="bg"></div>
                <img
                  src={selectedImage}
                  alt="Selected"
                  className="preview-image"
                />
              </>
            ) : (
              <p>Drag & Drop an image or click to select</p>
            )}
          </div>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleImageSelect}
          />
        </div>
      )}
      <div className="mt-4 disclosure">
        For a better experience we recommend using a desktop layout!{" "}
        <FontAwesomeIcon icon={faDesktop} />
      </div>
      <div className="mt-4">
        <div
          className="input-group mb-3 generateButtons"
          style={{ bottom: topParam + "px", left: leftParam + "%" }}
        >
          <div className="input-group-prepend">
            <Tippy content="Be aware! This button will toggle your censorship of the room!">
              <button
                className="btn btn-dark wt-dare-censorship"
                type="button"
                onClick={() => setCensorshipEnabled(!censorshipEnabled)}
              >
                <picture>
                  <source
                    srcSet={
                      !censorshipEnabled
                        ? "https://fonts.gstatic.com/s/e/notoemoji/latest/1f424/512.webp"
                        : "https://fonts.gstatic.com/s/e/notoemoji/latest/1f608/512.webp"
                    }
                    type="image/webp"
                  />
                  <img
                    src={
                      !censorshipEnabled
                        ? "https://fonts.gstatic.com/s/e/notoemoji/latest/1f424/512.gif"
                        : "https://fonts.gstatic.com/s/e/notoemoji/latest/1f608/512.gif"
                    }
                    alt="😈"
                    width="25"
                    height="25"
                  />
                </picture>
              </button>
            </Tippy>
          </div>
          <input
            className="form-control minutes wt-dare-minutes"
            placeholder="Minutes"
            aria-label="number of minutes"
            aria-describedby="basic-addon2"
            type="number"
            value={minutesInput}
            onKeyPress={handleInputKeyPress}
            onChange={(e) => setMinutesInput(e.target.value)}
          />
          <div className="input-group-append">
            <button
              className="btn btn-danger wt-dare-trigger trigger"
              type="button"
              onClick={handleGenerateCustomLink}
            >
              i dare you
            </button>
          </div>
        </div>
      </div>

      <Joyride
        steps={steps}
        run={runWalkthrough}
        continuous
        showProgress
        callback={handleJoyrideCallback}
        styles={{
          options: {
            arrowColor: "#1a1d25",
            backgroundColor: "#1a1d25",
            beaconSize: 36,
            overlayColor: "rgba(79, 26, 0, 0.6)",
            textColor: "#fff",
            zIndex: 1000,
          },
          spotlight: {
            borderRadius: "14px",
          },
          spotlightLegacy: {
            borderRadius: "14px",
          },
          buttonBack: {
            borderRadius: "8px",
            background: "transparent",
            border: 0,
            fontWeight: "600",
            color: "#fff",
            outline: 0,
            marginTop: 0,
          },
          buttonNext: {
            borderRadius: "8px",
            background: "#fff",
            color: "#121212",
            border: 0,
            fontWeight: "800",
            outline: 0,
            marginTop: 0,
          },
          tooltip: {
            borderRadius: "14px",
          },
          tooltipFooter: {
            marginTop: 0,
          },
          buttonClose: {
            marginTop: 0,
          },
          beaconInner: {
            outline: 0,
          },
          beaconOuter: {
            outline: 0,
          },
        }}
      />
    </div>
  );
};

export default LandingPage;
