import React from "react";
import Iframe from "react-iframe";

const LinkEmbed = ({ url, roomId }) => (
  <div className="link-player">
    <Iframe
      url={url}
      width="100%"
      height="100%"
      id=""
      className=""
      display="block"
      position="relative"
    />
  </div>
);

export default LinkEmbed;
