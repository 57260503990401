// Function to get the user's IP address
const getUserIPAddress = async () => {
  // Use a third-party service to get the user's IP address
  try {
    const response = await fetch("https://api64.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error);
    return null;
  }
};

// Function to get the current user identifier (using IP address in this example)
const getCurrentUserIdentifier = async () => {
  const ipAddress = await getUserIPAddress();
  return ipAddress;
};

const censorMessage = (message, EnglishBannedWordsVariations) => {
  const banWords = EnglishBannedWordsVariations; // Add your ban words here
  let censoredMessage = message;

  banWords.forEach((word) => {
    // Create a regex pattern for the banned word with variations
    const regexPattern = new RegExp(
      `(?:${word
        .split("")
        .map((char) => `[${char === "+" ? "\\+" : char}]`)
        .join("[^a-zA-Z]*")})`,
      "gi"
    );

    censoredMessage = censoredMessage.replace(
      regexPattern,
      "*".repeat(word.length)
    );
  });

  return censoredMessage;
};

const generateVariations = (word) => {
  const variations = [];

  // Add the original word to the variations
  variations.push(word);

  // Generate variations with symbols
  for (let i = 0; i < word.length; i++) {
    // Replace a character with an asterisk
    const asteriskVariation = word.split("");
    asteriskVariation[i] = "*";
    variations.push(asteriskVariation.join(""));

    // Replace a character with '@' if it's 'a' or 'A'
    if (word[i].toLowerCase() === "a") {
      const atVariation = word.split("");
      atVariation[i] = "@";
      variations.push(atVariation.join(""));
    }

    // Replace a character with '!' if it's 'i' or 'I'
    if (word[i].toLowerCase() === "i") {
      const exclamationVariation = word.split("");
      exclamationVariation[i] = "!";
      variations.push(exclamationVariation.join(""));
    }

    // Replace a character with '1' if it's 'l' or 'L'
    if (word[i].toLowerCase() === "l") {
      const oneVariation = word.split("");
      oneVariation[i] = "1";
      variations.push(oneVariation.join(""));
    }

    // Replace a character with '3' if it's 'e' or 'E'
    if (word[i].toLowerCase() === "e") {
      const threeVariation = word.split("");
      threeVariation[i] = "3";
      variations.push(threeVariation.join(""));
    }

    // Add other symbols or variations as needed
    // variations.push(...);
  }

  return variations;
};

const MAX_REPEATED_LETTERS = 4;

function hasRepeatedLetters(word) {
  const letterCounts = new Map();

  for (const letter of word) {
    const lowercaseLetter = letter.toLowerCase();

    if (!letterCounts.has(lowercaseLetter)) {
      letterCounts.set(lowercaseLetter, 1);
    } else {
      letterCounts.set(lowercaseLetter, letterCounts.get(lowercaseLetter) + 1);
    }

    if (letterCounts.get(lowercaseLetter) > MAX_REPEATED_LETTERS) {
      return true; // Word has too many repeated letters
    }
  }

  return false; // Word is within the allowed limit of repeated letters
}

function checkMessageForLetters(message) {
  const words = message.split(/\s+|(?=[.,;!?])/);
  const filteredMessage = words.filter((word) => hasRepeatedLetters(word));

  return filteredMessage.length;
}

export {
  getCurrentUserIdentifier,
  censorMessage,
  hasRepeatedLetters,
  checkMessageForLetters,
  generateVariations,
};
