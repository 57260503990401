import React, { useState, useEffect, useCallback, useRef } from "react";
import ReactDOM from "react-dom";
import { useParams, useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faCopy,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import firebase from "./firebase";
import InstagramEmbed from "./Embed/InstagramEmbed";
import TikTokEmbed from "./Embed/TikTokEmbed";
import YouTubeEmbed from "./Embed/YouTubeEmbed";
import TwitchEmbed from "./Embed/TwitchEmbed";
import { useModal } from "./ModalContext";
import {
  censorMessage,
  checkMessageForLetters,
  generateVariations,
} from "./functions";
import LinkEmbed from "./Embed/LinkEmbed";

import EnglishBannedWords from "./englishBannedWords.json";

const maxNumChar = 200;

const OnlyChat = () => {
  const { roomId } = useParams();
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const { openModal } = useModal();

  const [roomLink, setRoomLink] = useState("");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [countdown, setCountdown] = useState(null);
  const [dareLink, setDareLink] = useState("");
  const [imageURL, setImageURL] = useState(null);
  const [cooldown, setCooldown] = useState(false);
  const [censorshipEnabled, setCensorshipEnabled] = useState(false);
  const [chatVisible, setChatVisible] = useState(true);
  const [inactiveTimer1, setInactiveTimer1] = useState(null);
  const [inactiveTimer2, setInactiveTimer2] = useState(null);
  const [isRoomCreator, setIsRoomCreator] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(
    localStorage.getItem("currentUserId")
      ? localStorage.getItem("currentUserId")
      : null
  );
  const [isPoppedOut, setIsPoppedOut] = useState(false);

  const [poppedOutWindow, setPoppedOutWindow] = useState(null);

  // ... (existing code remains unchanged)

  const [EnglishBannedWordsVariations, setEnglishBannedWordsVariations] =
    useState([]);

  const db = firebase.firestore();

  const getMediaType = (link) => {
    if (/instagram\.com/.test(link)) {
      return "instagram";
    } else if (/tiktok\.com/.test(link)) {
      return "tiktok";
    } else if (/youtube\.com/.test(link)) {
      return "youtube";
    } else if (/twitch\.tv/.test(link)) {
      return "twitch";
    } else {
      return "other";
    }
  };

  const setMessagesAndUpdate = useCallback((newMessages) => {
    setMessages(newMessages);
  }, []);

  const deleteRoomAndRedirect = useCallback(
    async (after = false) => {
      try {
        await db
          .collection(`rooms/${roomId}/messages`)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref.delete();
            });
          });

        await db.collection("rooms").doc(roomId).delete();
        if (after) navigate("/missing-dare");
        else navigate("/dared");
      } catch (error) {
        console.error("Error deleting room:", error);
      }
    },
    [roomId, db, navigate]
  );

  const checkIfRoomExists = useCallback(async () => {
    try {
      const roomSnapshot = await db.collection("rooms").doc(roomId).get();
      if (!roomSnapshot.exists) {
        navigate("/missing-dare");
      }
    } catch (error) {
      console.error("Error checking if room exists:", error);
    }
  }, [roomId, db, navigate]);

  const fetchMessages = useCallback(async () => {
    try {
      if (roomId) {
        const snapshot = await db
          .collection(`rooms/${roomId}/messages`)
          .orderBy("timestamp")
          .get();
        const newMessages = snapshot.docs.map((doc) => doc.data());
        setMessagesAndUpdate(newMessages);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  }, [roomId, setMessagesAndUpdate, db]);

  const fetchImageURL = useCallback(async () => {
    try {
      const snapshot = await db.collection("rooms").doc(roomId).get();
      const data = snapshot.data();

      if (data && data.image) {
        setImageURL(data.image);
      }
    } catch (error) {
      console.error("Error fetching image URL:", error);
    }
  }, [roomId, db]);

  const subscribeToMessages = useCallback(() => {
    const unsubscribe = db
      .collection(`rooms/${roomId}/messages`)
      .orderBy("timestamp")
      .onSnapshot((snapshot) => {
        const newMessages = snapshot.docs.map((doc) => doc.data());
        setMessagesAndUpdate(newMessages);
      });

    return unsubscribe;
  }, [roomId, setMessagesAndUpdate, db]);

  const handleSendMessage = useCallback(async () => {
    try {
      if (newMessage.trim() !== "" && !cooldown) {
        if (newMessage.trim().length > maxNumChar) {
          openModal(`Your message is longer than ${maxNumChar} characters!`);
          return;
        }
        resetInactiveTimer();
        // Apply censorship if enabled
        if (censorshipEnabled && checkMessageForLetters(newMessage)) {
          openModal("Please dont spam letters in your message!");
          return;
        }

        // Check if the message contains a URL
        const urlRegex = /(https?:\/\/[^\s]+)/;
        if (urlRegex.test(newMessage)) {
          openModal("Links are strictly forbidden!");
          return;
        }

        const censoredMessage = censorshipEnabled
          ? censorMessage(newMessage, EnglishBannedWordsVariations)
          : newMessage;

        await db.collection(`rooms/${roomId}/messages`).add({
          text: censoredMessage,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          userId: currentUserId,
        });
        setNewMessage("");
        setCooldown(true);

        // Set a timeout to reset the cooldown after a specified time (e.g., 10 seconds)
        setTimeout(() => {
          setCooldown(false);
        }, 5000);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  }, [roomId, newMessage, db, firebase, cooldown, censorshipEnabled]);

  useEffect(() => {
    const fetchCensorshipFlag = async () => {
      try {
        const url = window.location.href;
        const domain = new URL(url);

        await setRoomLink("https://" + domain.hostname + "/chat/" + roomId);

        const roomSnapshot = await db.collection("rooms").doc(roomId).get();
        const roomData = roomSnapshot.data();

        if (roomData && roomData.censorshipEnabled) {
          setCensorshipEnabled(true);
        }
      } catch (error) {
        console.error("Error fetching censorship flag:", error);
      }
    };

    setEnglishBannedWordsVariations(
      EnglishBannedWords.flatMap((word) => generateVariations(word))
    );

    fetchCensorshipFlag();
  }, [roomId, db]);

  const fetchCountdown = useCallback(async () => {
    try {
      if (roomId) {
        const roomSnapshot = await db.collection("rooms").doc(roomId).get();
        const roomData = roomSnapshot.data();

        if (roomData && roomData.countdown && roomData.createdAt) {
          // Calculate the remaining time based on the difference between current time and createdAt
          const currentTime = new Date();
          const createdAt = roomData.createdAt.toDate(); // Convert Firestore timestamp to JavaScript Date

          const elapsedMilliseconds = currentTime - createdAt;
          const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
          const remainingSeconds = roomData.countdown * 60 - elapsedSeconds;

          // Update the countdown state
          setCountdown(remainingSeconds);

          if (roomData.dareLink) {
            setDareLink(roomData.dareLink);
          }

          // If the countdown reaches zero, delete the room and redirect
          if (remainingSeconds <= 0) {
            deleteRoomAndRedirect(true);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching and calculating countdown:", error);
    }
  }, [roomId, db, deleteRoomAndRedirect]);

  // Function to reset the inactive timer
  const resetInactiveTimer = () => {
    setChatVisible(true);
    clearTimeout(inactiveTimer1);
    clearTimeout(inactiveTimer2);

    // Set a new timer for 30 seconds of inactivity
    const chatHideTimer = setTimeout(() => {
      if (!isRoomCreator) setChatVisible(false);
    }, 30000);

    setInactiveTimer1(chatHideTimer);

    const kickOutTimer = setTimeout(() => {
      if (!isRoomCreator) navigate("/");
    }, 60000);

    setInactiveTimer2(kickOutTimer);
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const generateUniqueId = () => {
    return Math.random().toString(36).substring(2, 12);
  };

  // Reset the inactive timer on mount
  useEffect(() => {
    resetInactiveTimer();

    const uniqueId = generateUniqueId();
    setCurrentUserId(uniqueId);
    localStorage.setItem("currentUserId", uniqueId);

    return () => {
      localStorage.removeItem("currentUserId");
    };
  }, [isRoomCreator]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    checkIfRoomExists();
  }, [checkIfRoomExists]);

  useEffect(() => {
    fetchMessages();
    const unsubscribe = subscribeToMessages();
    fetchImageURL(); // Fetch image URL when the component mounts
    return () => unsubscribe();
  }, [fetchMessages, subscribeToMessages]);

  useEffect(() => {
    fetchCountdown();
  }, [roomId, fetchCountdown]);

  useEffect(() => {
    const checkRoomCreator = async () => {
      try {
        // Get the unique ID from local storage
        const storedUniqueId = localStorage.getItem("roomOwnerId");

        // Retrieve the room from Firestore
        const roomSnapshot = await firebase
          .firestore()
          .collection("rooms")
          .doc(roomId)
          .get();
        const roomData = roomSnapshot.data();

        // Check if the current user is the room creator
        setIsRoomCreator(roomData?.roomOwnerId === storedUniqueId);
      } catch (error) {
        console.error("Error checking room creator:", error);
      }
    };

    checkRoomCreator();
  }, [roomId]);

  useEffect(() => {
    let interval;

    if (countdown !== null && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      deleteRoomAndRedirect();
    }

    return () => {
      clearInterval(interval);
    };
  }, [countdown, deleteRoomAndRedirect]);

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleCopyUrl = async () => {
    try {
      const url = roomLink;
      await navigator.clipboard.writeText(roomLink);
      setRoomLink("Copied...");
      setTimeout(() => {
        setRoomLink(url);
      }, 3000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div className={`chatContainer`}>
      <div className="chat-wrapper only-chat">
        <div className="chat-room-tools desktop-only">
          <span className="chat-room-link">{roomLink}</span>
          <button
            className="chat-room-link-copy"
            onClick={() => handleCopyUrl()}
          >
            <FontAwesomeIcon icon={faCopy} />
          </button>
          <button className="chat-room-close" onClick={() => navigate("/")}>
            <FontAwesomeIcon className="chat-room-close-icon" icon={faClose} />
          </button>
        </div>
        {!chatVisible && (
          <div className="inactivity">
            <span>
              You fell asleep? Chat will dissapear if you do not dare for 30
              seconds, and kicked out after 60 seconds of inacitvity!
            </span>
          </div>
        )}
        <div
          className={[`chat-messages ${chatVisible ? "visible" : "hidden"}`]}
        >
          {messages && messages.length === 0 && (
            <div className="textanime">
              <p>We know it takes a lot of courage to dare</p>
            </div>
          )}
          {messages.map((message, index) => (
            <div
              key={index}
              className={`alert alert-info chat-message ${
                currentUserId === message.userId ? "current" : ""
              }`}
            >
              {message.text}
            </div>
          ))}
          <div ref={messagesEndRef} /> {/* Ref for auto-scrolling */}
        </div>
        <div className="chat-room-tools mobile-only">
          <span className="chat-room-link">
            {roomLink}
            <button
              className="chat-room-link-copy"
              onClick={() => handleCopyUrl()}
            >
              <FontAwesomeIcon icon={faCopy} />
            </button>
          </span>
          <button className="chat-room-close" onClick={() => navigate("/")}>
            <FontAwesomeIcon className="chat-room-close-icon" icon={faClose} />
          </button>
        </div>
        <div className="chat-input mt-3">
          {!cooldown ? (
            <textarea
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              className="form-control"
              autoFocus
              rows={1}
              maxLength={maxNumChar}
              placeholder={
                censorshipEnabled
                  ? "This room is censored!"
                  : "Type your message..."
              }
              onKeyPress={handleInputKeyPress}
            />
          ) : (
            <span className="cooldown-message">Cooldown 5 seconds</span>
          )}
          <button onClick={handleSendMessage} className="btn-send">
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
          {countdown !== null && (
            <div className="countdown">
              {Math.floor(countdown / 60)}:
              {(countdown % 60).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnlyChat;
