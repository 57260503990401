import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import firebase from "./firebase";
import RoomListItem from "./RoomListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Scrollbar } from "react-scrollbars-custom";

const RoomListPage = () => {
  const [openRooms, setOpenRooms] = useState([]);

  const fetchOpenRooms = async () => {
    try {
      const roomsSnapshot = await firebase
        .firestore()
        .collection("rooms")
        .get();
      const openRoomsData = [];

      roomsSnapshot.forEach((roomDoc) => {
        const roomData = roomDoc.data();

        if (roomData && roomData.countdown && roomData.createdAt) {
          const currentTime = new Date();
          const createdAt = roomData.createdAt.toDate();
          const elapsedMilliseconds = currentTime - createdAt;
          const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
          const remainingSeconds = roomData.countdown * 60 - elapsedSeconds;

          if (remainingSeconds > 0) {
            // Room is still open, add it to the list
            openRoomsData.push({
              roomId: roomDoc.id,
              dareLink: roomData.dareLink ? roomData.dareLink : roomData.image,
              type: determineRoomType(roomData.dareLink), // You need a function to determine the type
              remainingTime: remainingSeconds,
              createdAt: roomData.createdAt,
              countdown: roomData.countdown,
              censorshipEnabled: roomData.censorshipEnabled,
              roomType: roomData.roomType,
            });
          } else {
            // Room has expired, delete it
            deleteRoomAndMessages(roomDoc.id);
          }
        }
      });

      setOpenRooms(openRoomsData);
    } catch (error) {
      console.error("Error fetching open rooms:", error);
    }
  };

  useEffect(() => {
    fetchOpenRooms();
  }, []); // Empty dependency array to fetch open rooms once on component mount

  const determineRoomType = (dareLink) => {
    if (dareLink) {
      if (dareLink.includes("youtube.com") || dareLink.includes("youtu.be")) {
        return "YouTube";
      } else if (dareLink.includes("twitch.tv")) {
        return "Twitch";
      } else if (dareLink.includes("instagram.com")) {
        return "Instagram";
      } else if (dareLink.includes("tiktok.com")) {
        return "TikTok";
      }
    } else {
      return "Image"; // Assuming other links are image links
    }
  };

  const deleteRoomAndMessages = async (roomId) => {
    try {
      // Delete messages in the room
      const db = firebase.firestore();
      await db
        .collection(`rooms/${roomId}/messages`)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.delete();
          });
        });

      await db.collection("rooms").doc(roomId).delete();
    } catch (error) {
      console.error("Error deleting room and messages:", error);
    }
  };

  const navigate = useNavigate();
  return (
    <div className="container card room-list-card">
      <FontAwesomeIcon
        icon={faChevronLeft}
        onClick={() => navigate("/")}
        className="navigate-back"
      />
      <picture>
        <source
          srcSet="https://fonts.gstatic.com/s/e/notoemoji/latest/1f480/512.webp"
          type="image/webp"
        />
        <img
          src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f480/512.gif"
          alt="💀"
          width="50"
          height="50"
        />
      </picture>
      <h5>Do you dare the unkown?</h5>
      <table className="table">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th className="not-on-mobile">Link Type</th>
            <th>
              Time <span className="not-on-mobile">Remaining</span>
            </th>
            <th>Dares</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {openRooms.map((room) => (
            <RoomListItem
              key={room.roomId}
              room={room}
              callbackRefetch={fetchOpenRooms}
            />
          ))}
        </tbody>
      </table>
      {!openRooms.length && (
        <>
          <h4 className="no-dares">No unkown dares for now...</h4>
          <Link to={"/"} className="no-dares-link">
            But you can dare!
          </Link>
        </>
      )}
    </div>
  );
};

export default RoomListPage;
