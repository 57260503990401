import React from 'react';
import { Link } from 'react-router-dom';

const MissingDarePage = () => {
  return (
    <div className="missed-dare">
        <picture>
  <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f92c/512.webp" type="image/webp"/>
  <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f92c/512.gif" alt="🤬" width="50" height="50"/>
</picture>
      <h3 className="mb-4">Missed the dare?</h3>
      <Link to="/" className="btn btn-danger">But do you dare?</Link>
    </div>
  );
};

export default MissingDarePage;
