import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Scrollbar } from "react-scrollbars-custom";

const TermsAndConditionsPage = () => {
  const navigate = useNavigate();
  return (
    <div className="container card tos-card">
      <FontAwesomeIcon
        icon={faChevronLeft}
        onClick={() => navigate("/")}
        className="navigate-back"
      />
      <picture>
        <source
          srcSet="https://fonts.gstatic.com/s/e/notoemoji/latest/1f607/512.webp"
          type="image/webp"
        />
        <img
          src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f607/512.gif"
          alt="😇"
          width="50"
          height="50"
        />
      </picture>
      <h5>Terms and Conditions</h5>

      <h6>Welcome to Double Dare!</h6>

      <Scrollbar style={{ width: "100%", height: "500px" }}>
        <p>
          By accessing and using our web app, you agree to comply with and be
          bound by the following terms and conditions. If you disagree with any
          part of these terms, please do not use our service.
        </p>

        <p>
          <strong>1. Room Creation and Autodeleting Chat:</strong>
        </p>

        <p>
          &bull; Users can create chat rooms with a specified countdown timer.
          <br />
          &bull; The chat content will be automatically deleted after the
          countdown ends.
        </p>

        <p>
          <strong>2. Anonymity:</strong>
        </p>

        <p>
          &bull; Users have the option to chat anonymously within the created
          rooms.
        </p>

        <p>
          <strong>3. User Responsibility:</strong>
        </p>

        <p>
          {" "}
          &bull; Users are solely responsible for their interactions and
          messages within the app. <br />
          &bull; The creators of the room hold the responsibility for the chosen
          countdown duration.
        </p>

        <p>
          <strong>4. Content Guidelines:</strong>
        </p>

        <p>
          &bull; Users are prohibited from engaging in any form of hate speech,
          harassment, or bullying.
          <br />
          &bull; Offensive or inappropriate content is strictly prohibited.
        </p>

        <p>
          <strong>5. No Storage of Chat Data:</strong>
        </p>

        <p>
          &bull; We do not store any chat room data or messages after the
          countdown timer expires. <br />
          &bull; Our team does not have access to or control over the content
          generated within the app.
        </p>

        <p>
          <strong>6. Reporting Misuse:</strong>
        </p>

        <p>
          &bull; Users are encouraged to report any misuse or violations of our
          guidelines. <br />
          &bull; Our team will review reports and take appropriate action,
          including room suspension or removal.
        </p>

        <p>
          <strong>7. No Liability:</strong>
        </p>

        <p>
          &bull; We, as a team, are not responsible for the content shared in
          the chat rooms. <br />
          &bull; Users engage at their own risk, and we disclaim any liability
          for the consequences of using our service.
        </p>

        <p>
          <strong>8. Modification of Terms:</strong>
        </p>

        <p>
          &bull; We reserve the right to modify these terms at any time without
          prior notice. <br />
          &bull; Continued use of the app after changes implies acceptance of
          the modified terms.
        </p>

        <p>
          <strong>9. Termination of Service:</strong>
        </p>

        <p>
          &bull; We reserve the right to terminate the service or a user&rsquo;s
          access to it at our discretion. <br />
          &bull; Termination may occur in the event of a breach of these terms
          or misuse of the platform.
        </p>

        <p>
          <strong>10. Special Disclaimer:</strong>
        </p>

        <p>
          * This app is not an will not be indended for hateful speech, bully or
          any form of harassment. If you have mental issues and even dogs don’t
          love you, please consult a psychologist.
        </p>

        <p>
          <strong>11. Experience Mode:</strong>
        </p>

        <p>
          &bull; Double Dare offers an Experience Mode where users can speak
          freely about their life experiences.
          <br />
          &bull; Users are encouraged to share their thoughts and emotions
          anonymously.
          <br />
          &bull; While chatting in Experience Mode, users should be mindful of
          the potential impact of their messages on others.
        </p>

        <p>
          By using Double Dare, you acknowledge that you have read, understood,
          and agreed to be bound by these terms and conditions.
        </p>
      </Scrollbar>
    </div>
  );
};

export default TermsAndConditionsPage;
