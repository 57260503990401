import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import firebase from "./firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faShare, faSkull } from "@fortawesome/free-solid-svg-icons";

const RoomListItem = ({ room, callbackRefetch }) => {
  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeRemaining(room)
  );
  const [messageCount, setMessageCount] = useState(0);

  let interval = null;

  useEffect(() => {
    // Recalculate time remaining every second
    interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(room));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [room]);

  useEffect(() => {
    // Set up a listener to get real-time updates on the number of messages
    const unsubscribe = firebase
      .firestore()
      .collection(`rooms/${room.roomId}/messages`)
      .onSnapshot((snapshot) => {
        setMessageCount(snapshot.size); // Update message count on each snapshot
      });

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, [room.roomId]);

  useEffect(() => {
    if (timeRemaining <= 0) {
      deleteRoomAndMessages(room.roomId);
      clearInterval(interval);
    }
  }, [timeRemaining]);

  const deleteRoomAndMessages = async (roomId) => {
    try {
      // Delete messages in the room
      const db = firebase.firestore();
      await db
        .collection(`rooms/${roomId}/messages`)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.delete();
          });
        });

      await db.collection("rooms").doc(roomId).delete();
      callbackRefetch();
    } catch (error) {
      console.error("Error deleting room and messages:", error);
    }
  };

  return (
    <tr>
      <td className="roomid">
        <span className="adnotations not-on-mobile">#</span>
        {room.roomId}
      </td>
      <td>
        <picture>
          <source
            srcSet={
              room.censorshipEnabled
                ? "https://fonts.gstatic.com/s/e/notoemoji/latest/1f424/512.webp"
                : "https://fonts.gstatic.com/s/e/notoemoji/latest/1f608/512.webp"
            }
            type="image/webp"
          />
          <img
            src={
              room.censorshipEnabled
                ? "https://fonts.gstatic.com/s/e/notoemoji/latest/1f424/512.gif"
                : "https://fonts.gstatic.com/s/e/notoemoji/latest/1f608/512.gif"
            }
            alt="😈"
            width="25"
            height="25"
          />
        </picture>
      </td>
      <td className="not-on-mobile">
        {determineRoomType(room.dareLink, room.roomType)}
      </td>
      <td>{formatTimeRemaining(timeRemaining)}</td>
      <td>
        {messageCount} <span className="adnotations not-on-mobile">Comms</span>
      </td>
      <td>
        <Link to={`/chat/${room.roomId}`} className="btn dare-this-room">
          Dare <span className="not-on-mobile">this room</span>
          <FontAwesomeIcon icon={faSkull} />
        </Link>
      </td>
    </tr>
  );
};

// Helper function to calculate time remaining
const calculateTimeRemaining = (room) => {
  if (!room.createdAt) return 0;
  const currentTime = new Date();
  const createdAt = room.createdAt.toDate();
  const elapsedMilliseconds = currentTime - createdAt;
  const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
  const calculated = room.countdown * 60 - elapsedSeconds;
  return calculated >= 0 ? calculated : 0;
};

// Helper function to format time remaining as MM:SS
const formatTimeRemaining = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
  })}`;
};

const determineRoomType = (dareLink, roomType) => {
  if (!dareLink) {
    if (roomType) {
      if (roomType === "experience") return "X";
    }
  }

  if (dareLink.includes("youtube.com") || dareLink.includes("youtu.be")) {
    return "YouTube";
  } else if (dareLink.includes("twitch.tv")) {
    return "Twitch";
  } else if (dareLink.includes("instagram.com")) {
    return "Instagram";
  } else if (dareLink.includes("tiktok.com")) {
    return "TikTok";
  } else {
    return "Image"; // Assuming other links are image links
  }
};

export default RoomListItem;
