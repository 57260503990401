import React from 'react';
import { useNavigate } from 'react-router-dom';

const DaredPage = () => {
  const navigate = useNavigate();

  const handleReturnToMain = () => {
    navigate('/');
  };

  return (
    <div className="text-center you-dared">
        <picture>
  <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f979/512.webp" type="image/webp"/>
  <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f979/512.gif" alt="🥹" width="50" height="50"/>
</picture>
      <h3 className="mb-4">You Dared!</h3>
      <div className="mt-4">
        <button className="btn btn-primary" onClick={handleReturnToMain}>Do You Double Dare?</button>
      </div>
    </div>
  );
};

export default DaredPage;
