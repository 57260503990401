import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyC1bbWYHNuTwOIA6giTj6W91QN_wNPaa80",
  authDomain: "zvincapp.firebaseapp.com",
  projectId: "zvincapp",
  storageBucket: "zvincapp.appspot.com",
  messagingSenderId: "29201906911",
  appId: "1:29201906911:web:fdb2752e2ef00e31dbbd6a",
  measurementId: "G-4W3D1SYH41"
  };

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase;
